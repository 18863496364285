
var Masonry = require('masonry-layout');

var elem = document.querySelector('.masonrycontainer');

$(window).on('load', function() { 
    var msnry = new Masonry(elem, {
        // itemSelector: '.galerie-item'
    });
    
   

    $('.images-content-galerie .galerie-item a').fancybox();
    $('.roll-item a').fancybox();
    $('.bowl-item a').fancybox();
});