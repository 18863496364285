
jQuery(function() {

	var COOKIE_NOTICE = 'cookie-notice';

	
	$gonotice = $.cookie(COOKIE_NOTICE);
	if ($gonotice == null) {
        $('body').append('<div class="cookieConsentContainer" id="cookieConsentContainer"><div class="cookieTitle"><a>Cookies.</a></div><div class="cookieDesc"><p>Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait. <a href="politique-de-cookies.html">Politique de cookies</a><a class="closecookie">J\'ai compris</a></p></div>');
        $('#cookieConsentContainer').fadeIn();
	}
	$('#cookieConsentContainer .closecookie').on('click',function(){
		$.cookie(COOKIE_NOTICE, 'done', { path: '/', expires: 6 });
		$('#cookieConsentContainer').fadeOut();
	});
	
}); 