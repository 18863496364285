var connectionId = 34;
 

var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');

$(document).ready(function() {

    $.addTemplateFormatter("DateFormatter",

        function(value, template) {

            var date = new Date(value*1000);

            return date.toLocaleString().substring(0, 18);

    });



    $.addTemplateFormatter("Nl2BrFormatter",

        function(value, template) {

            return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');

    });



    // Don't load the feed if we're not on a listing page

    // Check if we are on a listing page

    if($('#lws-fb-feed-load').length) {



        $.ajax('https://www.lws-reviews.be/feed/' + connectionId, {

            success: function(response) {

                var feedItems     = response.data;

                var count = 0;

                var limit = $('#lws-fb-feed-load').attr('data-limit') ? $('#lws-fb-feed-load').attr('data-limit') : 100;



                for(var itemIndex in feedItems) {

                    let feedItem = feedItems[itemIndex];



                    // Display only page publications, we'll display customer publications elsewhere

                    if(!feedItem.from.link) continue;

                    if(count == limit) continue; 



                    // Load basic card

                    $("#lws-fb-feed-load ul").loadTemplate($("#fb-feed-item"), feedItem, { 

                        append: true,

                        afterInsert: function(elem) {

                            var cardBody = elem.find('.feed-item-body');



                            // Check if it's a shared post

                            if(feedItem.parent_id) {

                                var parent = feedItems.filter(function(item) {

                                    return item.id == feedItem.parent_id;

                                });

                                cardBody.loadTemplate($("#fb-feed-shared"), parent, {

                                    afterInsert: function(elem) {

                                        loadCardContent(elem, parent.shift());

                                    }

                                });

                            } else {

                                loadCardContent(elem, feedItem);

                            }

                        }

                    });



                    count ++;



                    

                }



                

                $('.btn-rm').click(function(e) {

                    e.preventDefault();

                    var $el = $(this);

                    var $p  = $el.parent();

                    $el.parents('.feed-item-message').css({

                        "height": 'auto',

                        "max-height": 9999

                    });

                    $p.fadeOut();

                    return false;

                  });

                //   console.log('inserted');
                  var elemmason = document.querySelector('.lws-feed-list');
                  imagesLoaded( elemmason, function() {
                    // images have loaded
                    var msnrynews = new Masonry(elemmason, {
                        // itemSelector: '.galerie-item'
                    });
                  });
                  

            }

        });



    }



});



function loadCardContent(elem, feedItem) {

    var cardBody = elem.find('.feed-item-body');



    if(feedItem.message) {

        cardBody.loadTemplate($("#fb-feed-message"), feedItem, { 

            append: true,

            afterInsert: function(elem) {

                if(elem.height() > 130 || elem.height() == 0) {

                    elem.append('<p class="read-more"><a href="#" class="btn btn-sm btn-secondary btn-rm">Afficher plus</a></p>');

                }

            }

        });

        

    }



    if(feedItem.attachments) {

        cardBody.loadTemplate($("#fb-feed-gallery"), feedItem, { 

            append: true,

            afterInsert: function(elem) {

                if(feedItem.attachments.data[0].type == "album") {

                    var cardGallery = elem.find('.feed-item-photo-gallery');

                    feedItem.attachments.data[0].subattachments.data.forEach(function(attachment) {

                        cardGallery.loadTemplate($("#fb-feed-added_photos-gallery_item"), attachment, { append: true });

                    });

                }

                if(feedItem.attachments.data[0].type == "photo") {

                    var cardGallery = elem.find('.feed-item-photo-gallery');

                    feedItem.attachments.data.forEach(function(attachment) {

                        cardGallery.loadTemplate($("#fb-feed-added_photos-single-picture"), attachment, { append: true });

                    });

                }

                if(feedItem.attachments.data[0].type == "share") {

                    var cardGallery = elem.find('.feed-item-photo-gallery');

                    feedItem.attachments.data.forEach(function(attachment) {

                        cardGallery.loadTemplate($("#fb-feed-added_photos-single-picture"), attachment, { append: true });

                    });

                }
                
            }

        });

    }

}