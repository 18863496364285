require("./resources/sass/styles.scss");

require("./resources/js/jquery-global");
require("popper.js");
require("bootstrap");

require("./resources/js/jquery.fancybox.js");
require("./resources/js/masonry");
require("./resources/js/contact-form");
require("./resources/js/nav");

require("./resources/js/jquery-template.js");
require("./resources/js/lwsreviews-feed");

require("./resources/js/jquery-cookies");
require("./resources/js/cookies");
