function auto_grow(element) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight)+"px";
}

$(document).ready(function() {
    var elem = document.querySelector('#contact-message');
    $('#contact-message').on('input',function() {
        //auto_grow(elem);
    });
});